import required from './required'
import min from './min'
import max from './max'
import email from './email'
import equals from './equals'

export default {
  required,
  min,
  max,
  email,
  equals
}