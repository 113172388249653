export default function (value, message = 'Campo obrigatório', condition = undefined) {
  if (typeof condition !== 'function') {
    return rule(value, message);
  }

  if (condition(value)) {
    return rule(value, message);
  } else {
    return message;
  }
}

function rule(value, message) {
  if (typeof value === 'string' && value.length) {
    return true;
  } else if (typeof value === 'boolean') {
    return value || message;
  }

  return message;
}