<template>
  <div class="page--register">
    <v-container fluid>
      <v-row>
        <v-col class="py-0">
          <v-breadcrumbs class="pa-5" :items="breadcrumbs"></v-breadcrumbs>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-col
        cols="12"
        offset="0"
        lg="8"
        offset-lg="2"
        md="10"
        offset-md="1"
        xl="6"
        offset-xl="3"
      >
        <v-card>
          <v-card-text>
            <h1 class="text-h6 black--text px-10 py-5">Registro</h1>
          </v-card-text>

          <v-card-text>
            <v-form
              ref="form"
              v-model="isFormValid"
              class="px-10"
              autocomplete="off"
            >
              <v-row>
                <v-col md="3" class="d-none d-md-flex align-center pb-8">
                  <label for="first_name">Primeiro Nome</label>
                </v-col>
                <v-col cols="12" md="9" class="d-block">
                  <label for="first_name" class="d-md-none"
                    >Primeiro Nome</label
                  >
                  <v-text-field
                    id="first_name"
                    v-model="person.name"
                    placeholder="Primeiro nome"
                    solo
                    dense
                    hint="Nos diga como você se identifica"
                    :rules="rules.name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="d-none d-md-flex align-center pb-8">
                  <label for="email">Email</label>
                </v-col>
                <v-col cols="12" md="9" class="d-block">
                  <label for="email" class="d-md-none">Email</label>
                  <v-text-field
                    id="email"
                    v-model="person.email"
                    placeholder="Email"
                    solo
                    dense
                    hint="Informe o seu e-mail para contato"
                    :rules="rules.email"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="d-none d-md-flex align-center pb-8">
                  <label for="password">Senha</label>
                </v-col>
                <v-col cols="12" md="9" class="d-block">
                  <label for="password" class="d-md-none">Senha</label>
                  <v-text-field
                    id="password"
                    v-model="person.password"
                    placeholder="Senha"
                    solo
                    dense
                    hint="Digite uma senha segura"
                    type="password"
                    :rules="rules.password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="3" class="d-none d-md-flex align-center pb-8">
                  <label for="password_confirmation"
                    >Confirmação de senha</label
                  >
                </v-col>
                <v-col cols="12" md="9" class="d-block">
                  <label for="password_confirmation" class="d-md-none"
                    >Confirmação de senha</label
                  >
                  <v-text-field
                    id="password_confirmation"
                    v-model="person.passwordConfirmation"
                    placeholder="Repetir senha"
                    solo
                    dense
                    hint="Digite novamente a senha que você digitou no campo anterior"
                    :rules="rules.passwordConfirmation"
                    type="password"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-flex align-center pb-0">
                  <label for="bio">Biografia</label>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-block">
                  <v-textarea
                    id="bio"
                    v-model="person.bio"
                    clearable
                    counter
                    solo
                    hint="Escreva em breves palavras um resumo de sua biografia"
                    placeholder="Biografia"
                    :rules="rules.bio"
                  ></v-textarea>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="d-md-flex align-center">
                  <v-checkbox
                    v-model="person.agreed"
                    color="primary"
                    :rules="rules.agreed"
                    class="float-left"
                  >
                    <template #label>
                      <div>
                        Concordo com os&nbsp;
                        <a href="#" @click.prevent>Termos de Serviço</a>
                        &nbsp;e&nbsp;
                        <a href="#" @click.prevent>Política de Privacidade</a>*
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-end pr-10 py-5">
            <v-btn
              text
              color="primary"
              :disabled="!isFormValid"
              @click.prevent="send"
              >Salvar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Validations from "services/validations/validations.js";
import pageLoadingMixin from "mixins/page-loading";

export default {
  name: "Register",
  mixins: [pageLoadingMixin],
  data: () => ({
    breadcrumbs: [
      {
        text: "Início",
        to: "/"
      },
      {
        text: "Exemplos",
        to: "/exemplos/listar"
      },
      {
        text: "Exemplo de Registros",
        to: "/exemplos/registrar"
      }
    ],
    isFormValid: undefined,
    person: {
      name: undefined,
      email: undefined,
      password: undefined,
      passwordConfirmation: undefined,
      bio: undefined,
      agreed: undefined
    }
  }),
  computed: {
    rules() {
      const self = this;
      return {
        name: [
          value => Validations.required(value),
          value => Validations.min(value, 2),
          value => Validations.max(value, 100)
        ],
        email: [
          value => Validations.required(value),
          value => Validations.max(value, 100),
          value =>
            Validations.email(
              value,
              ["educacao.pe.gov.br"],
              'Campo deve conter um email válido e com domínio "educacao.pe.gov.br"'
            )
        ],
        password: [
          value => Validations.required(value),
          value => Validations.min(value, 2),
          value => Validations.max(value, 100)
        ],
        passwordConfirmation: [
          value => Validations.required(value),
          value => Validations.min(value, 2),
          value => Validations.max(value, 100),
          value => value === self.person.password || "Senha incompatível"
        ],
        bio: [
          value => Validations.required(value),
          value => Validations.min(value, 2),
          value => Validations.max(value, 250)
        ],
        agreed: [value => Validations.required(value)]
      };
    }
  },
  beforeMount() {
    this.hidePageLoading();
  },
  methods: {
    send() {
      this.$refs.form.validate();

      if (this.isFormValid) {
        console.log("Enviou", this.person);
      }
    }
  }
};
</script>